import React from 'react'
import { MdDashboard } from "react-icons/md";
import { BiCategoryAlt } from 'react-icons/bi';

const Dashboard = React.lazy(() => import("../pages/dashboard/Dashboard"));
const Category = React.lazy(() => import("../pages/category/Category"));

export const superAdminRoutes = [
    {
        path: "/dashboard/data",
        element: <Dashboard />,
        name: "Dashboard",
        icon: <MdDashboard size={"1.5em"} />,
        sideBarVisible: true,
    },
    {
        path: "/dashboard/category",
        element: <Category />,
        name: "Category",
        icon: <BiCategoryAlt size={"1.5em"} />,
        sideBarVisible: true,
    },
];

export const adminRoutes = [
    {
        path: "/dashboard/data",
        element: <Dashboard />,
        name: "Dashboard",
        icon: <MdDashboard size={"1.5em"} />,
        sideBarVisible: true,
    },
    {
        path: "/dashboard/category",
        element: <Category />,
        name: "Category",
        icon: <BiCategoryAlt size={"1.5em"} />,
        sideBarVisible: true,
    },
];

export const deliveryRoutes = [
    {
        path: "/dashboard/data",
        element: <Dashboard />,
        name: "Dashboard",
        icon: <MdDashboard size={"1.5em"} />,
        sideBarVisible: true,
    }
];

export const storeKeeperRoutes = [
    {
        path: "/dashboard/data",
        element: <Dashboard />,
        name: "Dashboard",
        icon: <MdDashboard size={"1.5em"} />,
        sideBarVisible: true,
    }
];
